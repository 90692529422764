// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Questrial";
  src: url("/assets/fonts/Questrial/Questrial-Regular.ttf");
  font-weight: 300;
  font-style: normal;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #9cd326;
  --ion-color-primary-rgb: 156, 211, 38;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #89ba21;
  --ion-color-primary-tint: #a6d73c;

  /** secondary **/
  --ion-color-secondary: #0476c1;
  --ion-color-secondary-rgb: 4, 118, 193;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0468aa;
  --ion-color-secondary-tint: #1d84c7;

  /** tertiary **/
  --ion-color-tertiary: #ff642e;
  --ion-color-tertiary-rgb: 255, 100, 46;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e05828;
  --ion-color-tertiary-tint: #ff7443;

  /** success **/
  --ion-color-success: #62BD18;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #56a615;
  --ion-color-success-tint: #72c42f;

  /** warning **/
  --ion-color-warning: #FFBB00;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0a500;
  --ion-color-warning-tint: #ffc21a;

  /** danger **/
  --ion-color-danger: #D21034;
  --ion-color-danger-rgb: 255, 100, 46;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #b90e2e;
  --ion-color-danger-tint: #d72848;

  /** dark **/
  --ion-color-dark: #2a2a2a;
  --ion-color-dark-rgb: 42, 42, 42;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #252525;
  --ion-color-dark-tint: #3f3f3f;

  /** medium **/
  --ion-color-medium: #a5a5a5;
  --ion-color-medium-rgb: 165, 165, 165;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #919191;
  --ion-color-medium-tint: #aeaeae;

  /** light **/
  --ion-color-light: #F5F5F5;
  --ion-color-light-rgb: 245, 245, 245;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8d8d8;
  --ion-color-light-tint: #f6f6f6;

  // APP COLORS

  /** Lightgreen **/
  --ion-color-lightgreen: #9cd326;
  --ion-color-lightgreen-rgb: 156, 211, 38;
  --ion-color-lightgreen-contrast: #ffffff;
  --ion-color-lightgreen-contrast-rgb: 255, 255, 255;
  --ion-color-lightgreen-shade: #93a204;
  --ion-color-lightgreen-tint: #b0bf1e;

  /** Green **/
  --ion-color-green: #9cd326;
  --ion-color-green-rgb: 156, 211, 38;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #0a7d30;
  --ion-color-green-tint: #23994a;

  /** Bluegreen **/
  --ion-color-bluegreen: #0476c1;
  --ion-color-bluegreen-rgb: 4, 118, 193;
  --ion-color-bluegreen-contrast: #ffffff;
  --ion-color-bluegreen-contrast-rgb: 255, 255, 255;
  --ion-color-bluegreen-shade: #017d82;
  --ion-color-bluegreen-tint: #1a999f;

  /** Blue **/
  --ion-color-lightblue: #0476c1;
  --ion-color-lightblue-rgb: 4, 118, 193;
  --ion-color-lightblue-contrast: #ffffff;
  --ion-color-lightblue-contrast-rgb: 255, 255, 255;
  --ion-color-lightblue-shade: #65b7db;
  --ion-color-lightblue-tint: #81d5fa;

  /** Orange **/
  --ion-color-orange: #ff642e;
  --ion-color-orange-rgb: 203, 96, 21;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #b35412;
  --ion-color-orange-tint: #d0702c;

  /** Brown **/
  --ion-color-brown: #7e4f25;
  --ion-color-brown-rgb: 126, 79, 37;
  --ion-color-brown-contrast: #ffffff;
  --ion-color-brown-contrast-rgb: 255, 255, 255;
  --ion-color-brown-shade: #6f4621;
  --ion-color-brown-tint: #8b613b;

  /** Burgundy **/
  --ion-color-burgundy: #a21842;
  --ion-color-burgundy-rgb: 162, 24, 66;
  --ion-color-burgundy-contrast: #ffffff;
  --ion-color-burgundy-contrast-rgb: 255, 255, 255;
  --ion-color-burgundy-shade: #91153b;
  --ion-color-burgundy-tint: #be1c4d;

  /** Sidepanel Header **/
  --ion-color-sidepanel-header: #0476c1;
  --ion-color-sidepanel-header-rgb: 4, 118, 193;
  --ion-color-sidepanel-header-contrast: #ffffff;
  --ion-color-sidepanel-header-contrast-rgb: 255, 255, 255;
  --ion-color-sidepanel-header-shade: #65b7db;
  --ion-color-sidepanel-header-tint: #81d5fa;

  --ion-color-charge-slow: #62BD18;
  --ion-color-charge-slow-contrast: #ffffff;

  --ion-color-charge-standard: #FFBB00;
  --ion-color-charge-standard-contrast: #ffffff;

  --ion-color-charge-fast: #D21034;
  --ion-color-charge-fast-contrast: #ffffff;

  --ion-color-charge-rapid: #8F16B2;
  --ion-color-charge-rapid-contrast: #ffffff;

  --ion-color-header: var(--ion-color-primary);
  --ion-color-header-rgb: var(--ion-color-primary-rgb);
  --ion-color-header-contrast: var(--ion-color-primary-contrast);
  --ion-color-header-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-header-shade: var(--ion-color-primary-shade);
  --ion-color-header-tint: var(--ion-color-primary-tint);

  --ion-color-icon: #534f4f;
  --ion-color-icon-rgb: 83, 79, 79;
  --ion-color-icon-contrast: #ffffff;
  --ion-color-icon-contrast-rgb: 255, 255, 255;
  --ion-color-icon-shade: #494646;
  --ion-color-icon-tint: #646161;


  // BACKGROUND & FONTS

  --ion-background-color: #f5f5f5;
  --ion-background-color-rgb: 245, 245, 245;

  --ion-text-color: #534f4f;
  --ion-icon-color-rgb: 83, 79, 79;

  --ion-icon-color: #ffffff;
  --ion-icon-color-rgb: 255, 255, 255;

  --ion-color-thumbnail: #e8e8e8;
  --ion-text-color-thumbnail-rgb: 232, 232, 232;

  --swiper-navigation-color: var(--ion-color-secondary);

  --ion-text-color-header: #ffffff;
  --ion-text-color-header-rgb: 255, 255, 255;

  --ion-card-background: #ffffff;
  --ion-card-background-rgb: 255, 255, 255;
  --ion-card-color: var(--ion-text-color);

  --ion-tab-bar-background: var(--ion-color-primary);

  --ion-tab-button-color: var(--ion-color-primary-contrast);
  --ion-tab-button-color-focused: var(--ion-color-primary-contrast);
  --ion-tab-button-color-selected: var(--ion-color-primary-contrast);

  --ion-tab-button-background-color: var(--ion-color-primary);
  --ion-tab-button-background-color-focused: var(--ion-color-primary);
  --ion-tab-button-background-color-selected: var(--ion-color-primary-shade);

  --ion-toolbar-color: #ffffff;

  --ion-card-color: var(--ion-text-color);

  --ion-color-step-50: #efefef;
  --ion-color-step-100: #e8e8e8;
  --ion-color-step-150: #e2e2e2;
  --ion-color-step-200: #dcdcdc;
  --ion-color-step-250: #d6d6d6;
  --ion-color-step-300: #cfcfcf;
  --ion-color-step-350: #c9c9c9;
  --ion-color-step-400: #c3c3c3;
  --ion-color-step-450: #bcbcbc;
  --ion-color-step-500: #b6b6b6;
  --ion-color-step-550: #b0b0b0;
  --ion-color-step-600: #a9a9a9;
  --ion-color-step-650: #a3a3a3;
  --ion-color-step-700: #9d9d9d;
  --ion-color-step-750: #979797;
  --ion-color-step-800: #909090;
  --ion-color-step-850: #8a8a8a;
  --ion-color-step-900: #848484;
  --ion-color-step-950: #7d7d7d;

  --ion-font-family: "Questrial", sans-serif;

  --ion-input-background-color: var(--ion-card-background);
  --ion-input-background-color-rgb: var(--ion-card-background-rgb);
}

.slides-md,
.slides-ios {
  --bullet-background: rgba(var(--ion-text-color-rgb), 0.8);
  --bullet-background-active: var(--ion-text-color);
}

.ion-color-lightgreen {
  --ion-color-base: var(--ion-color-lightgreen);
  --ion-color-base-rgb: var(--ion-color-lightgreen-rgb);
  --ion-color-contrast: var(--ion-color-lightgreen-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightgreen-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightgreen-shade);
  --ion-color-tint: var(--ion-color-lightgreen-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-bluegreen {
  --ion-color-base: var(--ion-color-bluegreen);
  --ion-color-base-rgb: var(--ion-color-bluegreen-rgb);
  --ion-color-contrast: var(--ion-color-bluegreen-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bluegreen-contrast-rgb);
  --ion-color-shade: var(--ion-color-bluegreen-shade);
  --ion-color-tint: var(--ion-color-bluegreen-tint);
}

.ion-color-lightblue {
  --ion-color-base: var(--ion-color-lightblue);
  --ion-color-base-rgb: var(--ion-color-lightblue-rgb);
  --ion-color-contrast: var(--ion-color-lightblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightblue-shade);
  --ion-color-tint: var(--ion-color-lightblue-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-brown {
  --ion-color-base: var(--ion-color-brown);
  --ion-color-base-rgb: var(--ion-color-brown-rgb);
  --ion-color-contrast: var(--ion-color-brown-contrast);
  --ion-color-contrast-rgb: var(--ion-color-brown-contrast-rgb);
  --ion-color-shade: var(--ion-color-brown-shade);
  --ion-color-tint: var(--ion-color-brown-tint);
}

.ion-color-burgundy {
  --ion-color-base: var(--ion-color-burgundy);
  --ion-color-base-rgb: var(--ion-color-burgundy-rgb);
  --ion-color-contrast: var(--ion-color-burgundy-contrast);
  --ion-color-contrast-rgb: var(--ion-color-burgundy-contrast-rgb);
  --ion-color-shade: var(--ion-color-burgundy-shade);
  --ion-color-tint: var(--ion-color-burgundy-tint);
}

.ion-color-header {
  --ion-color-base: var(--ion-color-header);
  --ion-color-base-rgb: var(--ion-color-header-rgb);
  --ion-color-contrast: var(--ion-text-color-header-contrast);
  --ion-color-contrast-rgb: var(--ion-color-header-contrast-rgb);
  --ion-color-shade: var(--ion-text-color-header-shade);
  --ion-color-tint: var(--ion-color-header-tint);
}

.ion-color-sidepanel-header {
  --ion-color-base: var(--ion-color-sidepanel-header);
  --ion-color-base-rgb: var(--ion-color-sidepanel-header-rgb);
  --ion-color-contrast: var(--ion-color-sidepanel-header-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sidepanel-header-contrast-rgb);
  --ion-color-shade: var(--ion-color-sidepanel-header-shade);
  --ion-color-tint: var(--ion-color-sidepanel-header-tint);
}

// @media (prefers-color-scheme: dark) {
/*
   * Dark Colors
   * -------------------------------------------
   */

body.dark {
  /** Brown **/
  --ion-color-brown: #8b613b;
  --ion-color-brown-rgb: 139, 97, 59;
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-icon-color: #534f4f;
  --ion-icon-color-rgb: 83, 79, 79;

  --ion-text-color-header: #ffffff;
  --ion-text-color-header-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
  --ion-card-background-rgb: 26, 26, 26;
  --ion-input-background-color: var(--ion-card-background);

  --ion-card-color: var(--ion-text-color);

  --ion-color-thumbnail: #1a1a1a;
  --ion-text-color-thumbnail-rgb: 166, 166, 166;

  ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-text-header-color: #ffffff;
  --ion-text-header-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #333333;

  --ion-tab-bar-background: #333333;

  --ion-card-background: #1e1e1e;
  --ion-card-background-rgb: 30, 30, 30;
  --ion-card-color: var(--ion-text-color);
  --ion-input-background-color: var(--ion-card-background);
  --ion-input-background-color-rgb: var(--ion-card-background-rgb);
  --ion-toolbar-background: transparent;

  --ion-color-header: var(--ion-tab-bar-background);
  --ion-color-header-contrast: var(--ion-text-color);
  --ion-color-header-shade: #444444;

  --ion-color-sidepanel-header: var(--ion-tab-bar-background);
  --ion-color-sidepanel-header-contrast: var(--ion-tab-button-color);

  --ion-tab-button-color: var(--ion-color-primary-contrast);
  --ion-tab-button-color-focused: var(--ion-color-primary);
  --ion-tab-button-color-selected: var(--ion-color-primary);

  --ion-tab-button-background-color: var(--ion-tab-bar-background);
  --ion-tab-button-background-color-focused: var(--ion-tab-bar-background);
  --ion-tab-button-background-color-selected: var(--ion-tab-bar-background);

  --ion-color-light: #2a2a2a;
  --ion-color-light-rgb: 42, 42, 42;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #252525;
  --ion-color-light-tint: #3f3f3f;

  --ion-color-dark: #f5f5f5;
  --ion-color-dark-rgb: 245, 245, 245;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d8d8d8;
  --ion-color-dark-tint: #f6f6f6;

  --ion-color-thumbnail: #2a2a2a;
  --ion-text-color-thumbnail-rgb: 42, 42, 42;

  // ion-tab-button {
  //   --color: var(--ion-color-primary-contrast);
  //   --color-focused: var(--ion-color-primary-contrast);
  //   --color-selected: var(--ion-color-primary-contrast);

  //   --background: var(--ion-tab-bar-background);
  //   --background-focused: var(--ion-tab-bar-background);
  //   --background-selected: var(--ion-tab-bar-background);

  //   &:hover {
  //     --color-selected: var(--ion-color-primary-contrast);
  //   }

  //   &.tab-selected {
  //     border-top-color: var(--ion-color-primary);
  //     background-color: var(--ion-tab-bar-background);
  //     color: var(--ion-color-primary);

  //     &:hover {
  //       --color-selected: var(--ion-color-primary);
  //     }
  //   }
  // }
}
// }
