/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~swiper/swiper-bundle.min.css";
@import "~@ionic/angular/css/ionic-swiper.css";

/* Move Axeptio position above the tabs */
//#axeptio_overlay {
  //position: absolute;
  //bottom: 67px;

  //.bqzerq {
    //position: absolute;
  //}
//}

/* Typography */
a, p, ul, li {
  font-size: 16px;
  line-height: 1.4em;
}

h1 {
  font-size: 22px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 992px) {
    font-size: 28px;
  }
  @media (min-width: 1200px) {
    font-size: 28px;
  }
}

h2 {
  color: var(--ion-color-dark);
  font-size: 24px;
}

h3 {
  color: var(--ion-color-dark);
  padding-bottom: 20px;
}

h4 {
  color: var(--ion-color-dark);
  padding-bottom: 18px;
}

.subtitle {
  @media (min-width: 992px) {
    font-size: 22px;
  }
}

.wp-block-heading {
  margin-top: 20px;
  font-size: 22px;
  color: var(--ion-color-dark);
}

.wp-block-image {
  img {
    height: auto;
  }
}

#header-sidepanel {
  width: unset;
  margin-left: 0;

  ion-title {
    text-align: center;
    margin-left: -50px;

    &.ios {
      width: auto;
    }
  }
}

ion-header {
  &.header-md:after {
    background: none;
  }
  background: var(--ion-color-header);
  width: calc(100vw);
  margin-left: calc(-50vw + 50%);

  ion-toolbar {
    max-width: 1400px;
    margin: auto;
    height: auto;
    padding: 0;

    ion-title {
      padding: 0;
      color: var(--ion-color-header-contrast);
      padding-left: 10px;

      h1 {
        margin: auto;
        margin-top: 5px;
        margin-right: 0;
        color: var(--ion-color-header-contrast);
        font-size: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;

        @media (min-width: 768px) {
          font-size: 24px;
        }
        @media (min-width: 992px) {
          font-size: 28px;
        }
        @media (min-width: 1200px) {
          font-size: 28px;
        }
      }

      font-size: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (min-width: 768px) {
        font-size: 24px;
      }
      @media (min-width: 992px) {
        font-size: 28px;
      }
      @media (min-width: 1200px) {
        font-size: 28px;
      }
    }

    ion-button {
      font-weight: 500;
      color: var(--ion-color-secondary-contrast);
      background-color: var(--ion-color-secondary);
      padding: 0 5px;
      --background-activated: var(--ion-color-secondary-shade);
      margin-right: 0 !important;
      margin-left: 10px !important;
      border-radius: 10px;

      @media (min-width: 768px) {
        border-radius: 10px;
      }

      &.opened {
        color: var(--ion-color-primary);
        background-color: var(--ion-color-primary-contrast);
      }

      &.active {
        color: var(--ion-color-tertiary-contrast);
        background-color: var(--ion-color-tertiary);
      }

      &.action {
        color: var(--ion-color-tertiary-contrast);
        background-color: var(--ion-color-tertiary);
      }

      ion-icon,
      fa-icon {
        font-size: 16px;
      }

      .header-btn-label {
        font-weight: 400;
        margin-right: 5px;
        display: none;
        font-size: 16px;

        @media (min-width: 768px) {
          display: block;
        }
      }

      &.back-button {
        background-color: transparent;
      }
    }
  }
}

.action-sheet-group {
  --background: var(--ion-color-sidepanel-header);
  border-radius: 15px;
  margin-bottom: 8px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;

  .action-sheet-icon {
    color: #fff;
    margin-inline-end: 0.3em;
  }

  .action-sheet-button {
    --color: #fff;
    --button-color: #fff;
    --button-color-hover: #fff;
    font-size: 20px;
    font-weight: 500;
  }

  .action-sheet-button-inner {
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .action-sheet-cancel::after {
    background: var(--ion-color-sidepanel-header);
    opacity: 1;
  }
}

.picker-wrapper {
  --background: var(--ion-color-sidepanel-header);
  text-align: center;
  border-style: none !important;
  border-radius: 15px 15px 0 0 !important;

  .picker-button {
    color: #fff !important;
    font-size: 20px;
    font-weight: 500;
  }

  .picker-opt {
    color: #fff;
    font-size: 20px;
    font-weight: 500;

    &.picker-opt-selected {
      color: var(--ion-color-secondary);
      background: var(--ion-card-background);
      border-radius: 15px;
      font-weight: 700;
    }
  }
}

ion-skeleton-text {
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

ion-icon[name="person"],
ion-icon {
  pointer-events: none;
}

.disable-action-sheet-btns {
  opacity: 0.6 !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.hide-action-sheet-btns {
  display: none !important;
}

#no-result {
  width: fit-content;
  margin: auto;
  border: 1px solid var(--ion-color-step-100);
  border-radius: 10px;
  --background: var(--ion-card-background);
  --padding-top: 0;
  --padding-start: 15px;
  --padding-end: 15px;
  --padding-bottom: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  margin-top: 40px;
  margin-bottom: 40px;
  --border-radius: 10px;
  --border-color: transparent;

  &.link {
    --background: var(--ion-card-background);
    --color: var(--ion-color-secondary);
    border: 2px solid var(--ion-color-secondary);
    border-radius: 10px;
    cursor: pointer;
  }

  ion-label {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
}

ion-toolbar {
  --color: var(--ion-text-color);
  padding-left: 10px;
  padding-right: 5px;
  text-align: center;
  --border-style: none;
  --border-color: transparent;

  &.charge {
    --background: var(--ion-color-lightgreen);
    --color: var(--ion-color-lightgreen-contrast);
  }
  &.profil {
    --background: var(--ion-color-lightblue);
    --color: var(--ion-color-lightblue-contrast);
  }
  &.login {
    --background: #fff;
    --color: #000;

    .back-button {
      background-image: url(/assets/icon/back-button-dark.svg);
    }

    ion-title {
      padding: 0;

      img {
        width: 150px;
      }
    }
  }
  &.chat {
    --background: var(--ion-color-green);
    --color: var(--ion-color-green-contrast);
  }
  &.social {
    --background: var(--ion-color-green);
    --color: var(--ion-color-green-contrast);
  }
  &.blog {
    --background: var(--ion-color-burgundy);
    --color: var(--ion-color-burgundy-contrast);
  }
  &.pro {
    --background: var(--ion-color-brown);
    --color: var(--ion-color-brown-contrast);
  }
  &.store {
    --background: var(--ion-color-secondary);
    --color: var(--ion-color-secondary-contrast);
  }
  &.spot {
    --background: var(--ion-color-bluegreen);
    --color: var(--ion-color-bluegreen-contrast);
  }
  &.notifications {
    --background: var(--ion-color-primary);
    --color: var(--ion-color-primary-contrast);
  }

  ion-menu-button {
    color: var(--ion-color-header-contrast);
    margin-left: 10px;

    &::part(icon) {
      font-size: 38px;
    }
  }
}

ion-back-button,
.back-button {
  background-image: url(/assets/icon/chevron-left-white.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
  margin-left: -5px;
  margin-right: -10px;

  &::part(icon) {
    display: none;
  }
}

.back-button {
  height: 15px;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.form-group,
.list-multiple-checkboxes {
  text-align: left;
  margin: auto;

  ion-label {
    margin-bottom: 5px;
    font-weight: bold;
  }

  ion-input,
  ion-select,
  ion-textarea,
  ion-datetime,
  ion-searchbar {
    // height: 50px;
    margin: 10px auto;
    border: 1px solid var(--ion-color-step-100);
    border-color: var(--ion-color-step-100);
    border-radius: 10px;
    color: var(--ion-text-color);
    font-size: 16px;
    --placeholder-color: var(--ion-text-color);
    background-color: var(--ion-input-background-color);
    --background: var(--ion-input-background-color);
    --padding-top: 10px !important;
    --padding-start: 20px !important;
    --padding-end: 20px !important;
    --padding-bottom: 10px !important;
    --box-shadow: none;

    &.has-focus {
      border-color: var(--ion-color-base);
    }

    &[aria-disabled="true"] {
      opacity: 0.7;

      input {
        opacity: 1;
      }
    }
    &[readonly="true"],
    &[ng-reflect-readonly="true"] {
      --background: rgba(var(--ion-input-background-color-rgb), 0.5);
      background-color: rgba(var(--ion-input-background-color-rgb), 0.5);
    }
  }
  .fake-field {
    margin: 10px auto;
    border: 1px solid var(--ion-card-background);
    border-radius: 10px;
    color: var(--ion-text-color);
    background-color: var(--ion-input-background-color);
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  ion-searchbar {
    padding: 0;
    --border-radius: 10px;
    min-height: 36px;
    height: auto;
  }
  ion-select[aria-disabled="true"] {
    color: #b8b8b8;
  }
  ion-textarea.ion-color-danger,
  ion-input.ion-color-danger,
  ion-input.ng-invalid,
  ion-select.ng-invalid {
    //border-color: var(--ion-color-tertiary) !important;
    //margin-bottom: 5px !important;
  }
  .error-message {
    color: var(--ion-color-tertiary);
  }
}

ion-toolbar.ion-color.sc-ion-searchbar-ios-h:not(.ion-color) .searchbar-input.sc-ion-searchbar-ios,
ion-toolbar.ion-color
  .sc-ion-searchbar-ios-h:not(.ion-color)
  .searchbar-input.sc-ion-searchbar-ios {
  background-color: var(--ion-input-background-color);
  color: var(--ion-text-color);
}
ion-toolbar.ion-color.sc-ion-searchbar-ios-h:not(.ion-color)
  .searchbar-clear-button.sc-ion-searchbar-ios,
ion-toolbar.ion-color
  .sc-ion-searchbar-ios-h:not(.ion-color)
  .searchbar-clear-button.sc-ion-searchbar-ios {
  color: var(--ion-text-color);
}
.searchbar-input-container.sc-ion-searchbar-ios {
  height: 100%;
}

ion-header {
  .form-group {
    ion-searchbar {
      @media (min-width: 992px) {
        width: 50vw;
      }
      @media (min-width: 1200px) {
        width: 25vw;
      }
    }
  }

  ion-list {
    border-radius: 10px;
    display: flex;

    @media (min-width: 992px) {
      margin: 0 auto !important;
      width: 50vw;
    }
    @media (min-width: 1200px) {
      margin: 0 auto !important;
      width: 25vw;
    }

    ion-item {
      --padding-start: 10px;
      --padding-end: 10px;
      --border-style: none;
      --border-color: transparent;
    }
  }
}

ion-list {
  ion-item-option {
    border-radius: 15px !important;
    --padding-start: 15px;
    --padding-end: 15px;
    font-size: 16px;
    font-weight: bold;
    ion-icon {
      font-size: 24px;
    }
  }
}

ion-segment {
  --background: var(--ion-color-secondary-shade);
  --border-radius: 10px;

  ion-segment-button {
    --color: var(--ion-color-secondary-contrast);
    --color-checked: var(--ion-color-secondary-contrast);
    --border-color: none;
    --indicator-color: var(--ion-color-secondary);
    --border-radius: 10px;
    min-width: auto;
    padding: 0 10px;
    height: 40px;
    font-size: 16px;
    @media (min-width: 992px) {
      font-size: 14px;
    }
    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }
}

ion-card {
  box-shadow: none;
  border-radius: 15px;
  color: var(--ion-card-color);
  border: 1px solid var(--ion-color-step-100);

  ion-card-header {
    &.header {
      background: var(--ion-color-header);
      text-align: center;
      padding: 20px;
      margin-bottom: 10px;
      ion-card-title {
        color: #fff;
        font-size: 20px;
      }
    }
    &.title {
      background: var(--ion-color-step-150);
      text-align: center;
      padding: 10px;
      ion-card-title {
        color: var(--ion-text-color);
        font-size: 16px;
      }
    }
  }

  ion-card-title {
    text-transform: none;
    font-size: 18px;
    font-weight: 700;
    color: var(--ion-text-color);

    fa-icon {
      margin-right: 5px;
    }
  }

  ion-card-subtitle {
    text-transform: none;
    font-weight: 500;
    color: var(--ion-text-color);
    line-height: 1.4em;
  }

  .form-group {
    ion-input,
    ion-select,
    ion-textarea,
    ion-datetime,
    ion-searchbar,
    .fake-field {
      background-color: none;
      --background: none;
    }
  }
}

ion-searchbar {
  --color: var(--ion-text-color) !important;
  --placeholder-color: var(--ion-text-color) !important;
  --background: var(--ion-input-background-color) !important;
}

.select-interface-option {
  --padding-start: 10px;
}

.checkbox-container,
.radio-container {
  ion-checkbox {
    margin: 0;
    margin-right: 10px;
  }
  ion-label {
    white-space: normal !important;
  }
}

ion-radio-group {
  ion-item {
    --padding-start: 0;

    ion-label {
      line-height: 17px !important;
      margin: 8px 0;
      white-space: normal !important;
    }
  }
}

.table {
  width: 100%;

  td,
  th {
    padding: 0.75rem;
    border-top: 1px solid var(--ion-color-step-900);
    min-width: 100px;
    text-align: center;
  }
  thead {
    th {
      border-bottom: 2px solid var(--ion-color-step-900);
      color: var(--ion-color-dark);

      &.sortable {
        cursor: pointer;
      }
      &.is-sorted {
        font-weight: bold;

        &.asc:after {
          content: "▲";
        }
        &.desc:after {
          content: "▼";
        }
        &.asc:after,
        &.desc:after {
          display: inline-block;
          margin-left: 5px;
        }
      }
    }
  }
  tbody {
    th {
      text-align: left;
    }
  }
  tr.clickable {
    cursor: pointer;

    // &:hover,
    // &:focus {
    //   background-color: var(--ion-text-color);
    //   color: var(--ion-card-background);
    // }
  }

  &.table-view {
    tbody {
      th,
      td {
        width: 25vw;
      }
      th {
        text-align: right;
      }
      td {
        text-align: left;
      }
    }
  }

  &.table-condensed {
    td,
    th {
      padding: 5px;
      min-width: none;
    }
  }
}
.table-warning {
  tbody + tbody,
  td,
  th,
  thead th {
    border-color: var(--ion-color-secondary-shade);
  }

  &,
  & > td,
  & > th {
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast);
  }
}
.current-page {
  @media (min-width: 576px) {
    padding-top: 16px;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;

  .link-mention {
    color: var(--ion-color-primary);
  }
}

ion-textarea.textarea-modal {
  min-height: 40px;
  textarea {
    min-height: 40px;
  }
}

.input-error-message {
  ion-note {
    display: block;
    
  }
}

.media-uploader {
  .empty-img {
    margin-bottom: 10px;

    ion-icon {
      font-size: 30px;
      position: relative;
      top: 10px;
    }
  }

  input[type="file"] {
    display: none;
  }

  img {
    max-width: 200px;
  }
}

a.link.link-hashtag {
  text-decoration: none;
  color: var(--ion-color-secondary);
  font-weight: bold;
}

/** ION-BUTTONS **/
ion-button {
  --border-radius: 10px;
  --box-shadow: none;
  text-transform: inherit;
  font-weight: 500;
  --min-height: 40px;
  --padding-top: 15px;
  --padding-bottom: 15px;
  letter-spacing: 0;

  .btn-label {
    font-size: 16px;
    font-weight: 400;
    margin-right: 5px;
  }

  &.main {
    --padding-end: 30px;
    --padding-start: 30px;
    display: block;
    max-width: 500px;
    margin: 20px auto;
    --min-height: 50px;
    font-size: 16px;
    font-weight: 500;

    &.button-disabled {
      opacity: 0.6;
    }
  }

  &.action {
    border-radius: 10px;
    color: var(--ion-color-secondary-contrast);
    background-color: var(--ion-color-secondary);
    &.select {
      opacity: 0.6;
    }
  }

  .action-btn-label {
    font-size: 16px;
    font-weight: 400;
    margin-right: 5px;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }
}

.alert-modal .modal-wrapper {
  // max-width: 500px !important;
  // width: 80% !important;
  // min-width: unset !important;
  // height: unset !important;
  // min-height: unset !important;
  // max-height: 90% !important;
  border-radius: 15px;
  overflow-y: scroll;

  .ion-page {
    display: inherit;
    position: inherit;
    contain: inherit;
  }
}

.alert-wrapper.sc-ion-alert-md {
  border-radius: 15px;
}

.picker-prefix,
.picker-suffix,
.picker-opt.picker-opt-selected,
.picker-button.sc-ion-picker-md,
.picker-button.ion-activated.sc-ion-picker-md {
  color: var(--ion-color-secondary);
}

ion-alert {
  &.alert-lg {
    --max-width: 500px;

    .alert-wrapper {
      width: 100%;
    }
  }

  .alert-button-group.sc-ion-alert-md {
    justify-content: center;

    .alert-button {
      border-radius: 30px !important;
      padding-left: 15px !important;
      padding-right: 15px !important;

      .alert-button-inner {
        justify-content: center;
      }
    }
  }
  .alert-button {
    color: var(--ion-color-secondary) !important;

    &.grey {
      background-color: var(--ion-color-secondary);
      color: var(--ion-color-secondary-contrast);
    }
    &.orange {
      background-color: var(--ion-color-secondary);
      color: var(--ion-color-secondary-contrast);
    }
  }
  .select-interface-option,
  .alert-radio-group {
    &[aria-checked="true"] {
      &.sc-ion-alert-ios,
      &.sc-ion-alert-md {
        .alert-radio-label {
          & {
            color: var(--ion-color-secondary);
          }
        }
        .alert-radio-inner {
          &,
          &.sc-ion-alert-ios {
            border-color: var(--ion-color-secondary);
          }
        }
      }
      .alert-radio-icon {
        border-color: var(--ion-color-secondary) !important;
      }
      .alert-checkbox-icon {
        background-color: var(--ion-color-secondary) !important;
      }
    }

    .alert-button-inner {
      .alert-radio-icon,
      .alert-checkbox-icon {
        &.sc-ion-alert-ios {
          border-color: var(--ion-color-secondary);
        }
        &.sc-ion-alert-md {
          border-color: var(--ion-color-secondary) !important;

          .alert-radio-inner {
            background-color: var(--ion-color-secondary);
          }
        }
      }
    }
  }
}
[aria-checked="true"].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
  color: var(--ion-color-secondary);
}
[aria-checked="true"].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
  border-color: var(--ion-color-secondary);
}

fieldset {
  legend {
    text-align: center;
  }
}

ion-app {
  &.hide-interface {
    ion-header,
    ion-menu {
      display: none;
    }
  }
}

ion-modal {
  --border-radius: 0;

  &.sheet-modal {
    --border-radius: 15px;
  }

  @media (min-width: 768px) {
    --border-radius: 15px;
  }

  &.stream-message-add-modal {
    @media (min-width: 768px) {
      --height: 400px;
    }
  }
  &.small-modal {
    --width: 330px;
    --height: 330px;
    --border-radius: 15px;

    .ion-toolbar {
      height: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--ion-color-header-contrast);
    }

    &::-webkit-scrollbar {
      display: none !important;
      width: 0 !important; /* Remove scrollbar space */
      background: transparent !important; /* Optional: just make scrollbar invisible */
    }
  }

  &.large-modal {
    --width: 100%;
    --max-width: 100vw;
    --border-radius: 0;
  }

  ion-header {
    background: var(--ion-color-header);
    width: 100%;
    margin-left: 0;
    text-align: center;

    ion-title {
      font-size: 20px;
      @media (min-width: 768px) {
        font-size: 22px;
      }
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    ion-button {
      font-weight: 500;
      color: var(--ion-color-secondary-contrast);
      background-color: var(--ion-color-secondary);
      border-radius: 10px;
      padding: 0 5px;
      --background-activated: var(--ion-color-secondary-shade);
      margin: 0 5px !important;

      &.opened {
        color: var(--ion-color-primary);
        background-color: var(--ion-color-primary-contrast);
      }

      &.active {
        color: var(--ion-color-secondary-contrast);
        background-color: var(--ion-color-secondary);
      }

      &.action {
        color: var(--ion-color-tertiary-contrast);
        background-color: var(--ion-color-tertiary);
      }

      &.back-button {
        background-color: transparent;
      }

      &.close {
        background-color: var(--ion-color-header);
        ion-icon {
          font-size: 28px;
        }
      }

      .header-btn-label {
        font-weight: 500;
        margin-right: 5px;
        display: none;
        font-size: 15px;

        @media (min-width: 768px) {
          display: block;
        }
      }
    }

    ion-icon {
      --background-hover: transparent;
    }
  }
}

.modal-lg {
  --width: 100%;
  --max-width: 100vw;

  @media (min-width: 768px) {
    --max-width: 500px;
  }
}

#slider-didacticiel {
  .swiper-pagination {
    top: 240px;
    bottom: auto;
  }
}

ion-header {
  ion-title {
    text-align: left;
  }
}
// We force ion-buttons[slot="start"] et ion-buttons[slot="end"] to have minimum width, even without buttons inside.
// It helps to center ion-title in ion-header
// .buttons-first-slot,
// .buttons-last-slot {
//   min-width: 48px;

//   &.ios {
//     min-width: 41px;
//   }
// }
.custom-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;

  &.icon-mixed {
    background-image: url(/assets/icon/mixed-default.svg);
  }
  &.icon-highway {
    background-image: url(/assets/icon/highway-default.svg);
  }
  &.icon-city {
    background-image: url(/assets/icon/city-default.svg);
  }
  &.icon-winter {
    background-image: url(/assets/icon/winter-default.svg);
  }
  &.icon-summer {
    background-image: url(/assets/icon/summer-default.svg);
  }
}
body.dark,
.active {
  .custom-icon {
    &.icon-mixed {
      background-image: url(/assets/icon/mixed-white.svg);
    }
    &.icon-highway {
      background-image: url(/assets/icon/highway-white.svg);
    }
    &.icon-city {
      background-image: url(/assets/icon/city-white.svg);
    }
    &.icon-winter {
      background-image: url(/assets/icon/winter-white.svg);
    }
    &.icon-summer {
      background-image: url(/assets/icon/summer-white.svg);
    }
  }
}

.accordion {
  margin: 20px 0;

  .accordion-panel {
    margin-bottom: 3px;

    &.lightgreen {
      &,
      & .accordion-panel-header {
        background-color: var(--ion-color-lightgreen);
        color: var(--ion-color-lightgreen-contrast);
      }

      .table th,
      .table td,
      .accordion-panel-header,
      .accordion-panel-content {
        border-color: var(--ion-color-lightgreen-contrast);
      }

      &.active {
        & .accordion-panel-header {
          color: inherit;
        }
      }
    }
    &.green {
      &,
      & .accordion-panel-header {
        background-color: var(--ion-color-green);
        color: var(--ion-color-green-contrast);
      }

      .table th,
      .table td,
      .accordion-panel-header,
      .accordion-panel-content {
        border-color: var(--ion-color-lightgreen-contrast);
      }

      &.active {
        & .accordion-panel-header {
          color: inherit;
        }
      }
    }
    &.bluegreen {
      &,
      & .accordion-panel-header {
        background-color: var(--ion-color-bluegreen);
        color: var(--ion-color-bluegreen-contrast);
      }

      .table th,
      .table td,
      .accordion-panel-header,
      .accordion-panel-content {
        border-color: var(--ion-color-lightgreen-contrast);
      }

      &.active {
        & .accordion-panel-header {
          color: inherit;
        }
      }
    }
    &.lightblue {
      &,
      & .accordion-panel-header {
        background-color: var(--ion-color-lightblue);
        color: var(--ion-color-lightblue-contrast);
      }

      .table th,
      .table td,
      .accordion-panel-header,
      .accordion-panel-content {
        border-color: var(--ion-color-lightgreen-contrast);
      }

      &.active {
        & .accordion-panel-header {
          color: inherit;
        }
      }
    }

    .accordion-panel-header {
      border: 1px solid var(--ion-text-color);
      background-color: var(--ion-card-background);
      color: var(--ion-text-color);
      padding: 15px 10px;
      text-align: center;
      cursor: pointer;

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }

    .accordion-panel-content {
      display: none;
      border: 1px solid var(--ion-text-color);
      border-top: 0;
      padding: 15px 10px;
    }

    &.active {
      & > .accordion-panel-header {
        color: var(--ion-color-primary);
      }

      & > .accordion-panel-content {
        display: block;
      }
    }
  }
}

.container-el {
  max-width: 1400px;
  margin: auto;
}

// .swiper-pagination {
//   bottom: -25px !important;
// }

.datetime-modal {
  color: var(--ion-text-color);
  --max-width: 350px;
  --max-height: 339px;

  &.with-time {
    --max-height: 386px;
  }
}

.month-picker-modal {
  color: var(--ion-text-color);
  --max-width: 350px;
  --max-height: 240px;

  &.ios {
    --max-height: 200px;
  }
}

.sheet-modal {
  --max-width: 90%;
  --max-height: 620px;
}

.vehicule-picker-with-battery-selector {
  ion-header {
    ion-toolbar {
      padding-top: 0;
    }
  }
  --max-height: 460px;
}

.header-logo {
  height: 40px;
  margin-top: 0;
  margin-bottom: 3px;
}

.footer-md::before {
  display: none;
}

.ul-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.date-modal-opener {
  margin: 10px 0;
  display: block;
}

.search-container {
  ion-searchbar {
    padding: 0;
    margin-bottom: 5px;
    --border-radius: 10px;
  }
  ion-list {
    flex-direction: column;
    ion-item {
      --padding-top: 0 !important;
      --padding-start: 20px !important;
      --padding-end: 0 !important;
      --padding-bottom: 0 !important;
      line-height: 30px;
      height: 30px;
      ion-label {
        margin-top: -7px;
      }
    }
  }
}

ion-list.search-options {
  border-radius: 10px;
}

ion-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}

.swiper .swiper-slide {
  height: auto;
  display: block;
}
.centered-swiper {
  overflow: visible;

  &,
  .swiper-wrapper,
  .swiper-slide {
    height: 100% !important;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-pagination {
    position: absolute;
    bottom: -20px;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;

    .swiper-pagination-bullet {
      margin: 2px;
      opacity: 1;
      // border: 1px solid var(--bullet-background-active);
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}
.trends-swiper {
  overflow: visible;

  &,
  .swiper-wrapper,
  .swiper-slide {
    height: 100% !important;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-pagination {
    position: absolute;
    bottom: -20px;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;

    .swiper-pagination-bullet {
      margin: 2px;
      opacity: 1;
      // border: 1px solid var(--bullet-background-active);
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      opacity: 0.2;

      &.swiper-pagination-bullet-active {
        background-color: var(--bullet-background-active);
        opacity: 1;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    background-color: transparent;
  }
}

.loading-wrapper {
  &.sc-ion-loading-md,
  &.sc-ion-loading-ios {
    border-radius: 15px;
  }
}

.ion-accordion-toggle-icon {
  color: inherit;
}

#tab-content {
  min-height: calc(100vh - 49px - 48px - 60px);
}

.swiper-tab-station {
  &,
  .swiper-slide {
    min-height: calc(100vh - 49px - 48px - 60px);
  }
}

.power-plugs-swiper {
  overflow: visible;

  .swiper-slide {
    width: 150px;
  }

  .swiper-pagination {
    position: absolute;
    bottom: -20px;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;

    .swiper-pagination-bullet {
      margin: 2px;
      opacity: 1;
      // border: 1px solid var(--bullet-background-active);
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;

      &.swiper-pagination-bullet-active {
        background-color: var(--ion-text-color);
      }
    }
  }
}

.has-password-display-btn {
  position: relative;

  &.has-required-label {
    ion-button {
      top: 11px;

      &.md {
        top: 17px;
      }
    }
  }

  ion-button {
    position: absolute;
    top: -4px;
    right: 0;
    margin: 0;
    order: 2;
    z-index: 999;
    width: auto !important;
    margin-top: 0;

    &.md {
      top: 2px;
    }
  }

  ion-input {
    --padding-end: 60px !important;
  }
}

.main-title.ios {
  //padding-left: 60px;
}